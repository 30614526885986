<script setup>
import helper from '~/resources/js/libraries/helper';
import MobileListingCardFavorite from './partials/favorite-card.vue';


const isLoading = ref(true);
const listings = ref([]);
const currentCurrency = ref(helper.cookies.currency());
listings.value = JSON.parse(helper.localStorage.getData('last-seen'))?.reverse() || [];
isLoading.value = false;
</script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.last_seen')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>

    <div class="mb-32 relative">
      <template v-if="!isLoading">
        <template v-if="listings.length">
          <div class="my-2 px-4">
            <div class="mb-3" v-for="listing in listings">
              <NuxtLink class="flex gap-2" :to="localePath({ name: 'listings.show', params: { slug: listing.seo.slug } })">
                <div class="w-60 h-36 object-fill block">
                  <img :src="`${listing.picture}?w=300&h=300`" :alt="listing.title"
                    class="rounded-lg h-36 w-60 object-cover">
                </div>
                <div class="flex flex-col gap-2 w-full">
                  <div class="flex justify-between items-center">
                    <h2 class="opacity-80 text-black text-xl font-bold">
                      {{ currentCurrency.code }}
                      {{ helper.formatPrice(listing.sale_price) }}
                    </h2>
                    <mobile-listing-card-favorite :listingId="listing._id"></mobile-listing-card-favorite>
                  </div>
                  <h3 class="opacity-60 text-black text-sm font-medium">
                    {{ listing.title }}
                  </h3>
                </div>
              </NuxtLink>
            </div>
          </div>
        </template>
        <template v-else>
          No Listings
        </template>
      </template>
      <template v-else>
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <span class="loader" v-if="isLoading"></span>
        </div>
      </template>
    </div>
  </NuxtLayout>
</template>